import { useSearchParams } from 'react-router'
import { useEffect, useState } from 'react'
import { Button } from './button'

export function LoadMoreButton({
	text,
	loadingText,
	page,
	pageSize,
	count,
}: {
	text: string
	loadingText: string
	page: number
	pageSize: number
	count: number
}) {
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const show = count >= page * pageSize

	const fetchNextPage = async () => {
		setIsLoading(true)
		const newSearchParams = new URLSearchParams(searchParams.toString())
		newSearchParams.set('page', (page + 1).toString())
		setSearchParams(newSearchParams, { preventScrollReset: true })
	}

	useEffect(() => {
		setIsLoading(false)
	}, [count])

	return (
		<>
			{show && (
				<div className="my-8 flex justify-center">
					<Button
						variant="outline-light"
						size="lg"
						onClick={fetchNextPage}
						disabled={isLoading}
					>
						{isLoading ? loadingText : text}
					</Button>
				</div>
			)}
		</>
	)
}
